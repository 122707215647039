import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { catchError, debounceTime, finalize, Observable, of, Subject, switchMap, tap } from 'rxjs';

import { Item, searchResult } from '../types/googleSearch';
import { ApiProductosService } from './api-productos.service';

// Esto se utiliza para no respoder un atributo vacio en caso de que haya error en la consulta o no haya resultados
export const DEFAULT_RESPONSE_GOOGLE: searchResult = {
  kind: "",
  url: {
    type: "",
    template: ""
  },
  queries: {
    request: [
      {
        title: "",
        totalResults: "",
        searchTerms: "",
        count: 0,
        startIndex: 0,
        inputEncoding: "",
        outputEncoding: "",
        safe: "",
        cx: ""
      }
    ]
  },
  context: {
    title: ""
  },
  searchInformation: {
    searchTime: 0,
    formattedSearchTime: "",
    totalResults: "",
    formattedTotalResults: ""
  },
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class ApiBuscadorGoogleService {
  preciosos: any = []
  precioInd: any;
  private apiKey: string = 'AIzaSyAk5j4McvDwgABOseW9NtWJ4U5npeKPHyk'; //Nueva apikey
  private searchEngineId: string = '72f3d3fcd275e4e7e';
  private apiUrl: string = 'https://www.googleapis.com/customsearch/v1';

  private http = inject(HttpClient)

  private loadingBool = signal(false);
  private lastSearch = "";

  private searchSubject = new Subject<{ query: string, start: number, sort: string }>();
  private searchResultSubject = new Subject<searchResult>();

  totalResultados = 0;


  constructor(private apiProductos: ApiProductosService) {
    this.searchSubject.pipe(
      debounceTime(500), // Ajusta el tiempo de debounce según sea necesario
      switchMap(params => this.realSearch(params.query, params.start, params.sort))
    ).subscribe({
      next: (response) => this.searchResultSubject.next(response),
      error: (error) => this.searchResultSubject.error(error)
    });
  }

  search(query: string, start: number = 1, sort: string = ''): Observable<searchResult> {
    this.loadingBool.set(true);

    this.searchSubject.next({ query, start, sort });
    return this.searchResultSubject.asObservable();
  }

  realSearch(query: string, start: number = 1, sort: string = ''): Observable<searchResult> {

    let params = new HttpParams()
      .set('key', this.apiKey)
      .set('cx', this.searchEngineId)
      .set('q', query)
      .set('start', start.toString());

    if (sort) {
      params = params.set('sort', sort);
    }
    return this.http.get<searchResult>(this.apiUrl, { params }).pipe(
      tap(resp => {
        console.log({ resp })
        this.lastSearch = query;
        this.totalResultados = Number(resp.searchInformation.totalResults);

        if (resp.items) {
          resp.items = resp.items.filter(e => e.link.split("/").length > 4);

          //Se agregan los resultados forzados en pagina 1:
          if (resp.queries.request[0].startIndex < 10) {
            for (let [clave, v] of miMapa) {
              if (query.toLocaleLowerCase().includes(clave)) {
                resp.items = resp.items?.concat(v)
              }
            }
          }

          for (let e of resp.items) {

            let tipo: "Categoría" | "Producto" | "Paquete" | null = null;
            const link = e.link

            //Esta secciona cambia el orden de los resultados (Mostrando primero siempre plotters)
            if (link.toLocaleLowerCase().includes('plotter')) {
              resp.items.splice(resp.items.indexOf(e), 1);
              resp.items.unshift(e)
            }


            const casi = link.split("/")
            if (casi.length == 5 || casi.length == 6) {


              const casi2 = casi[4].split("-")
              const ids = casi2[0];

              const titulo = e.title
              if (link.includes('/catalogo/') || link.includes('/categor')) {
                tipo = "Categoría"
              }
              else if (link.includes('/catalogo/') && !link.includes('/paquetes/')) {
                tipo = "Producto"
              }
              else {
                tipo = "Paquete"
              }

              if (!e.pagemap.metatags[0]['product:price'] || e.pagemap.metatags[0]['product:price'] == '0') {
                // CODIGO AQUI CUANDO NO TENGA PRECIO;

                if (tipo !== "Categoría") {
                  this.apiProductos.checaPrecioGoogle(ids).subscribe(preciosos => {
                    this.preciosos = preciosos

                    //Asignasion de precio; QUITAR EN PRODUCCION
                    e.pagemap.metatags[0]['product:price'] = this.preciosos
                  })
                }

              }
              if (true) {
                //CODIGO DE IMAGEN EQUIBOCADA AQUI

                // console.log(`Paquete con imagen mal: ${titulo} ${ids}`)

                if (tipo == "Paquete") {
                  this.apiProductos.getDescripcionPaquete(ids).subscribe(r => {
                    if (r) {
                      e.pagemap.cse_image[0].src = r[0].image_link
                    }
                  })
                }
                else if (tipo == "Categoría") {
                  //TODO: Fix: categoria
                  this.apiProductos.getCategorias(ids).subscribe(r => {
                    if (r) {
                      e.pagemap.cse_image[0].src = r[0].image_link
                    }
                  })
                }

              }
            }


          }
        }

      }),
      catchError(this.handleError<searchResult>('getData', DEFAULT_RESPONSE_GOOGLE)),
      finalize(() => this.loadingBool.set(false))
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    this.totalResultados = 0;
    return (error: HttpErrorResponse): Observable<T> => {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Error del lado del cliente
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // Error del lado del servidor
        errorMessage = `Código de error: ${error.status}\nMensaje: ${error.message}`;
      }
      // console.error(`${operation} falló: ${errorMessage}`);
      // Retorna un resultado vacío o por defecto para mantener la aplicación funcionando
      return of(result as T);
    };
  }

  isLoading() {
    return this.loadingBool()
  }

}


// Hardcode respuesta de busquedas que nos pidan;
// Devuelve productos especificos para una palabra (en la pagina 1),  el producto se puede sacar del console log
const miMapa = new Map<string, Item[]>([
  ['venture', [{
    "kind": "customsearch#result",
    "title": "Plotter de Corte Profesional Cricut Venture Smart | 2008739",
    "htmlTitle": "Plotter de Corte Profesional Cricut <b>Venture</b> Smart | 2008739",
    "link": "//cricut-plotter-de-corte/6710-plotter-de-corte-profesional-cricut-venture-smart--2008739.html",
    "displayLink": "lideart.com.mx",
    "snippet": "Conoce Cricut Venture, nuestra máquina de corte profesional de gran formato. Corta con precisión más de 100 materiales hasta 22.8 m (75 pies) y funciona a ...",
    "htmlSnippet": "Conoce Cricut <b>Venture</b>, nuestra máquina de corte profesional de gran formato. Corta con precisión más de 100 materiales hasta 22.8 m (75 pies) y funciona a&nbsp;...",
    "formattedUrl": "https://lideart.com.mx/.../6710-plotter-de-corte-profesional-cricut-venture-s...",
    "htmlFormattedUrl": "https://lideart.com.mx/.../6710-plotter-de-corte-profesional-cricut-<b>venture</b>-s...",
    "pagemap": {
      "cse_thumbnail": [
        {
          "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqCCdLWwj5YkwoKkpHb76a5Az8W2wvWwjIV9YjGodC8Q4q5X5bC2K8Pn4&s",
          "width": "225",
          "height": "225"
        }
      ],
      "metatags": [
        {
          "og:image": "https://new.lideart.com.mx/img/p/3/3/7/7/2/33772.jpg",
          "viewport": "viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
          "product:price": "16998.9996"
        }
      ],
      "cse_image": [
        {
          "src": "https://new.lideart.com.mx/img/p/3/3/7/7/2/33772.jpg"
        }
      ]
    }
  }]]
])